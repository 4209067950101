<script setup lang="ts">
import type { CmsElementProductSlider } from "@shopware/composables";

const props = defineProps<{
  content: CmsElementProductSlider;
}>();
const { getConfigValue } = useCmsElementConfig(props.content);

const productSlider = ref<HTMLElement>();
const slidesToShow = ref<number>();
const products = computed(() => props.content?.data?.products ?? []);

onMounted(() => {
  setTimeout(() => {
    let temp = 1;
    const minWidth = +getConfigValue("elMinWidth").replace(/\D+/g, "");
    if (productSlider.value?.clientWidth) {
      temp = Math.ceil(productSlider.value?.clientWidth / (minWidth * 1.2));
    }
    slidesToShow.value = temp;
  }, 100);
});

const title = computed(() => getConfigValue("title"));
</script>

<template>
  <div ref="productSlider">
    <ProductSlider class="mb-5" :title="title" :products="products" />
  </div>
</template>
